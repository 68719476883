var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"redirectionForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('ValidationProvider',{attrs:{"vid":"parent_id","rules":"required","name":"The Parent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"parent_id","size":"large","filterable":"","clearable":"","placeholder":"Parent","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Parent *',"list":_vm.pageList,"listItemLabel":'label',"listItemValue":'id'},model:{value:(_vm.formData.parent_id),callback:function ($$v) {_vm.$set(_vm.formData, "parent_id", $$v)},expression:"formData.parent_id"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"page_name","rules":"required","name":"The Page Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Page Name *","name":"page_name","fou":""},model:{value:(_vm.formData.page_name),callback:function ($$v) {_vm.$set(_vm.formData, "page_name", $$v)},expression:"formData.page_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"redirection_type","rules":"required","name":"The Redirection Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"redirection_type","size":"large","filterable":"","clearable":"","placeholder":"Redirection Type","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Redirection Type *',"list":_vm.redirectionTypes,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.redirection_type),callback:function ($$v) {_vm.$set(_vm.formData, "redirection_type", $$v)},expression:"formData.redirection_type"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"redirection_url","rules":"required","name":"The Redirection Url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Redirection Url *","name":"redirection_url","fou":""},model:{value:(_vm.formData.redirection_url),callback:function ($$v) {_vm.$set(_vm.formData, "redirection_url", $$v)},expression:"formData.redirection_url"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"redirection_target","rules":"required","name":"The Redirection Target"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"redirection_target","size":"large","filterable":"","clearable":"","placeholder":"Redirection Type","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Redirection Target *',"list":_vm.redirectionTargets,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.redirection_target),callback:function ($$v) {_vm.$set(_vm.formData, "redirection_target", $$v)},expression:"formData.redirection_target"}})]}}],null,true)}),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Is Active")]),_vm._v("  "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)],1),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/pages/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }